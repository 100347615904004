import React, { useReducer } from "react"

import { WellnessSurveyReducer } from "./WellnessSurveyReducer"
// import { useAirtableSurveyFormFields } from "./hooks/useAirtableSurveyFormFields"

// import { generateInitialValues } from "services/context"

const WellnessSurveyContext = React.createContext()

const WellnessSurveyProvider = ({ children }) => {
  // let formFields = useAirtableSurveyFormFields()

  let [surveyState, surveyDispatch] = useReducer(WellnessSurveyReducer, {
    // ...generateInitialValues({ fields: formFields }),
    documents: [],
    toast: {
      isActive: false,
      message: null,
      color: null,
    },
  })

  return (
    <WellnessSurveyContext.Provider value={{ surveyState, surveyDispatch }}>
      {children}
    </WellnessSurveyContext.Provider>
  )
}

export { WellnessSurveyContext, WellnessSurveyProvider }
