import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import { AppProvider } from "./src/context/AppContext"
import { ConsultationProvider } from "./src/components/Consultations/ConsultationContext/ConsultationContext"
import { WellnessSurveyProvider } from "./src/components/WellnessSurvey/context/WellnessSurveyContext"

import { getFirebase } from "services/firebase/firebase"
import "./static/styles/global.scss"

export const wrapRootElement = ({ element }) => {
  getFirebase()

  return (
    <AppProvider>
      <ConsultationProvider>
        <WellnessSurveyProvider>
          <ApolloProvider client={client}>{element}</ApolloProvider>
        </WellnessSurveyProvider>
      </ConsultationProvider>
    </AppProvider>
  )
}
