import React, { useReducer } from "react"

import Modal from "elements/Modal"

import { ConsultationReducer } from "./ConsultationReducer"
import { useAirtableConsultationFormFields } from "./hooks/useAirtableConsultationFormFields"

import { generateInitialValues } from "../../../services/context"

const ConsultationContext = React.createContext()

const ConsultationProvider = ({ children }) => {
  let formFields = useAirtableConsultationFormFields()

  let [consultationState, consultationDispatch] = useReducer(
    ConsultationReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      toast: {
        isActive: false,
        message: null,
        color: null,
      },
      modal: {
        isCard: false,
        isFullheight: false,
        isActive: false,
        content: null,
        heading: "",
        headerClass: null,
        hideCloseButton: false,
        background: {
          color: null,
          opacity: "100",
        },
      },
    }
  )

  return (
    <ConsultationContext.Provider
      value={{ consultationState, consultationDispatch }}
    >
      <Modal
        isModalActive={consultationState.modal.isActive}
        isFullheight={consultationState.modal.isFullheight}
        closeModal={() => consultationDispatch({ type: "HIDE_MODAL" })}
        heading={consultationState.modal.heading}
        modalHeaderClass={consultationState.modal.headerClass}
        modalBackground={consultationState.modal.background}
        hideCloseButton={consultationState.modal.hideCloseButton}
        isCard={consultationState.modal.isCard}
      >
        {consultationState.modal.content}
      </Modal>
      {children}
    </ConsultationContext.Provider>
  )
}

export { ConsultationContext, ConsultationProvider }
