import { isBrowser } from "../../services/general"
import { parse, stringify } from "flatted/esm"

export const getContextFromSession = () =>
  isBrowser() && sessionStorage.getItem("contextState")
    ? parse(sessionStorage.getItem("contextState"))
    : {}

export const saveContextToSession = (state) => {
  if (isBrowser())
    sessionStorage.setItem("contextState", stringify({ ...state }))
}

export const removeContextFromSession = () => {
  if (isBrowser()) sessionStorage.removeItem("contextState")
}
